<template>
  <div id="bsModalSendFunds" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                <label for="amount">Amount</label>
                <input id="amount" v-model="form.Amount" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="payout" class="form-group">
                <span class="mr-4">H:</span>
                <a href="javascript:void(0)" class="mr-8" @click="deductFromAmount(payout.SendFee)">
                  {{ payout.SendFee }}
                </a>
                <span class="mr-4">L:</span>
                <a href="javascript:void(0)" class="mr-8" @click="deductFromAmount(payout.SendFeeLow)">
                  {{ payout.SendFeeLow }}
                </a>
                <a href="javascript:void(0)" @click="deductFromAmount(0)">
                  Remove Fee
                </a>
              </div>
              <ValidationObserver v-slot="{ errors }" class="form-group" name="priority" rules="required" tag="div">
                <select v-model="form.Fee" aria-label="" class="form-control">
                  <option value="2">Pay less is slower -
                    <span v-if="payout">{{ Number(payout.SendFeeLow).toFixed(8) + ' ' + payout.LongName }}</span>
                  </option>
                  <option value="0">Use standard fee</option>
                  <option value="1">Pay more is faster -
                    <span v-if="payout">{{ Number(payout.SendFee).toFixed(8) + ' ' + payout.LongName }}</span>
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationObserver>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="merchant id" rules="required" tag="div">
                <label for="merchantID">Merchant</label>
                <select id="merchantID" v-model="form.MerchantID" class="form-control" @change="populate()">
                  <option v-for="(merchant, index) in merchants" :key="index" :value="merchant.id">{{ merchant.name }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider class="form-group" name="coin address" rules="required" tag="div" v-slot="{ errors }">
                <input v-model="form.coinAddress" type="hidden" />
                <template v-if="addresses.length > 0">
                  <label for="coinAddress-select">Coin Address</label>
                  <select class="form-control" id="coinAddress-select" @change="setCoinAddress">
                    <option value="">Select Crypto Address</option>
                    <option v-for="(address, index) in addresses" :key="index"
                      :selected="address.address === form.coinAddress" :value="address.address">{{ address.address }}
                    </option>
                  </select>
                </template>
                <template v-else>
                  <label for="coinAddress-text">Coin Address</label>
                  <input v-model="form.coinAddress" class="form-control" type="text" id="coinAddress-text" />
                </template>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="altCoinID" rules="required" tag="div">
                <label for="altCoinID">AltCoinID</label>
                <select id="altCoinID" v-model="form.AltCoinID" class="form-control">
                  <option v-for="(altCoin, index) in onlineAltCoins" :key="index" :value="altCoin.ID">
                    {{ altCoin.Name + ' - ' + altCoin.LongName }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="charging method" rules="required"
                tag="div">
                <label for="chargingMethod">Charging Method</label>
                <input id="chargingMethod" v-model="form.ChargingMethod" class="form-control" readonly type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="payout month" rules="required" tag="div">
                <label for="payoutMonth">Payout Month</label>
                <select id="payoutMonth" v-model="form.PayoutMonth" class="form-control">
                  <option v-for="(month, index) in months" :key="index" :value="month.id">{{ month.name }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="total transactions" rules="required"
                tag="div">
                <label for="totalTransactions">Total Transactions</label>
                <input id="totalTransactions" v-model="form.TransactionTotal" class="form-control" readonly type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="api key" rules="required" tag="div">
                <label for="apiKey">API Key</label>
                <input id="apiKey" v-model="form.APIKey" class="form-control" readonly type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="customer reference" rules="required"
                tag="div">
                <label for="customerReference">Customer Reference</label>
                <input id="customerReference" v-model="form.CustomerReferenceNr" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="tag" tag="div"
                v-if="selectedCoin && selectedCoin.Tag === 1">
                <label for="tag">Tag</label>
                <input id="tag" v-model="form.Tag" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Crypto withdraw</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification, toFixed } from "@/lib/utils";
import * as _ from "lodash";

export default {
  name: "PayoutModal",
  data() {
    return {
      isLoading: false,
      title: "",
      merchants: [],
      form: {
        MerchantID: "",
        Amount: "",
        coinAddress: "",
        AltCoinID: "",
        ChargingMethod: "",
        PayoutMonth: "",
        TransactionTotal: "",
        APIKey: "",
        CustomerReferenceNr: "",
        Fee: 0,
      },
      merchant: null,
      addresses: [],
      payout: null,
      months: [
        {
          id: 1,
          name: "January",
        },
        {
          id: 2,
          name: "February",
        },
        {
          id: 3,
          name: "March",
        },
        {
          id: 4,
          name: "April",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "June",
        },
        {
          id: 7,
          name: "July",
        },
        {
          id: 8,
          name: "August",
        },
        {
          id: 9,
          name: "September",
        },
        {
          id: 10,
          name: "October",
        },
        {
          id: 11,
          name: "November",
        },
        {
          id: 12,
          name: "December",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
    ]),
    selectedCoin() {
      if (this.form.AltCoinID) {
        return _.find(this.onlineAltCoins, { ID: this.form.AltCoinID });
      }
      return null;
    },
  },
  watch: {
    "form.AltCoinID"(val) {
      if (this.selectedCoin && this.selectedCoin.Tag === 1) {
        this.form = Object.assign(this.form, { Tag: "" });
      } else {
        delete this.form.Tag;
      }
    },
  },
  methods: {
    confirm() {
      this.close();
    },
    populate() {
      if (this.payout === null) {
        const payout = this._.find(this.payouts, { MerchantID: this.form.MerchantID });
        if (payout) {
          this.fetchMerchantDetail(this.payout.MerchantID);
          this.form.APIKey = payout.APIKey;
          this.form.CustomerReferenceNr = "Automated Payout";
          this.form.ChargingMethod = "fixed-fee";
          this.form.TransactionTotal = 1;
          this.form.output = "json";
          if (payout.AddressBookCrypto !== "NA") {
            if (payout.AddressBookCrypto.altCoinID === payout.AltCoinID) {
              this.form.coinAddress = payout.AddressBookCrypto.address;
              if (this.form.Tag) {
                this.form.Tag = this.payout.AddressBookCrypto.tag;
              }
            }
          }
        }
      }
    },
    setCoinAddress(evt) {
      this.form.coinAddress = evt.target.options[evt.target.selectedIndex].value;
    },
    onSubmit() {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/GetSendToAddress`, this.qs.stringify(payload), {}).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$toast.fire("", response.data, "success");
          saveNotification(response.data);
          // Close modal
          this.close();
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error?.data || error, "error");
        saveNotification(error?.data || error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    fetchMerchantDetail(id) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=UserDetail`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          ID: id,
        },
      }).then(response => {
        if (response.status === 200) {
          this.merchant = Object.assign({}, response.data.data);
          const addressBook = JSON.parse(this.merchant.AddressBook);
          const cryptoAddressBook = addressBook.crypto;
          const addresses = cryptoAddressBook[this.payout.AltCoinID];
          if (addresses) {
            this.addresses = addresses;
          } else {
            this.addresses = [];
          }
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    close() {
      this.form = {
        MerchantID: "",
        Amount: "",
        coinAddress: "",
        AltCoinID: "",
        ChargingMethod: "",
        PayoutMonth: "",
        TransactionTotal: "",
        APIKey: "",
        CustomerReferenceNr: "",
        Fee: 0,
      };
      this.$nextTick(() => this.$refs.observer.reset());
      $("#bsModalSendFunds").modal("hide");
    },
    deductFromAmount(val) {
      this.form.Amount = toFixed(this.payout.Balance - val);
    },
  },
  mounted() {
    this.$bus.$on("send-funds-modal", (args) => {
      this.title = args.title;
      const data = args.data;
      this.addresses = [];
      this.isLoading = false;
      const month = (new Date()).getMonth() + 1;
      if (data) {
        if (!Array.isArray(data)) {
          this.payout = data;
          if (this.payout) {
            this.fetchMerchantDetail(this.payout.MerchantID);
            this.merchants = [];
            this.merchants.push({
              id: this.payout.MerchantID,
              name: this.payout.ShopTitle,
            });
            this.form = {
              MerchantID: this.payout.MerchantID,
              Amount: toFixed(this.payout.Balance),
              coinAddress: "",
              ChargingMethod: "fixed-fee",
              PayoutMonth: month,
              AltCoinID: this.payout.AltCoinID,
              TransactionTotal: this.payout.TotalTransactions,
              APIKey: this.payout.APIKey,
              CustomerReferenceNr: "Automated Payout",
              Fee: 0,
              output: "json",
            };
            if (this.payout.AddressBookCrypto !== "NA") {
              if (this.payout.AddressBookCrypto.altCoinID === this.payout.AltCoinID) {
                this.form.coinAddress = this.payout.AddressBookCrypto.address;
                if (this.form.Tag) {
                  this.form.Tag = this.payout.AddressBookCrypto.tag;
                }
              }
            }
          }
        } else {
          this.payouts = data;
          this.payout = null;
          if (this.payouts.length > 0) {
            this.merchants = [];
            this.payouts.forEach(payout => {
              const index = this._.findIndex(this.merchants, { id: payout.MerchantID });
              if (index === -1) {
                this.merchants.push({
                  id: payout.MerchantID,
                  name: payout.ShopTitle,
                });
              }
            });
            this.merchants = this._.orderBy(this.merchants, ["name"], ["asc"]);
            this.form.PayoutMonth = month;
          }
        }
      }
      $("#bsModalSendFunds").modal("show");
    });
  },
};
</script>

<style scoped></style>
