<template>
  <div aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static" data-keyboard="false" data-toggle="modal"
    id="bsModalPayoutSwap" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">Swap</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" rules="required" name="altCoinId" tag="div">
                <label for="altCoinId">AltCoin</label>
                <v-select id="altCoinId" v-model="form.AltCoinID" :options="coins" :reduce="c => c.ID" taggable
                  label="Name"></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="amt" />
                  </div>
                  <div class="col-sm-6">
                    <select class="form-control" v-model="selectedInputCurrency">
                      <option v-for="currency in inputCurrencies" :key="currency.ID" :value="currency.ShortName">
                        {{ `${currency.Name} (${currency.LongName})` }}
                      </option>
                    </select>
                  </div>
                </div>
                <a href="javascript:void(0)" v-if="fiatData"
                  @click="form.Amount = cryptoVal">{{ `${cryptoVal} ${fiatData.LongName}` }}</a>
              </div>
              <ValidationProvider v-slot="{ errors }" class="form-group" :rules="{ required: true, regex: /^[0-9]+/ }"
                name="amount" tag="div">
                <div class="flex align-items-center justify-between">
                  <label for="amount">Amount ({{ record ? record.LongName : '' }})</label>
                  <a href="javascript:void(0)" v-if="record"
                    @click="form.Amount = record.Balance">{{ `${record.Balance} ${record.LongName}` }}</a>
                </div>
                <input id="amount" class="form-control" v-model="form.Amount">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Swap</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { saveNotification, toFixed } from "@/lib/utils";

export default {
  name: "PayoutSwapModal",
  data() {
    return {
      isLoading: false,
      apiBaseUrl: process.env.VUE_APP_API_URL,
      inputCurrencies: [],
      record: null,
      coins: [],
      selectedAltCoinID: null,
      amt: "",
      fiatData: null,
      selectedInputCurrency: "EUR",
      cryptoVal: 0,
      form: {
        AltCoinID: "",
        Amount: "",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "currentUser",
      "altCoins",
    ]),
  },
  watch: {
    amt(val) {
      if (val) {
        this.calculate();
      }
    },
    selectedInputCurrency(val) {
      if (val) {
        this.fiatLookup(true);
      }
    },
  },
  methods: {
    confirm() {
      this.confirmHook();
      this.close();
    },
    calculate() {
      this.cryptoVal = (this.amt > 0 && this.fiatData) ? toFixed(this.amt / this.fiatData.OutputValue) : 0;
    },
    toFixed(val) {
      return toFixed(val);
    },
    fetchInputCurrencies() {
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "inputCurrencyList",
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          output: "json",
        },
      }).then(response => {
        if (response.data && response.data.length > 0) {
          this.inputCurrencies = response.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    fiatLookup(isCalculate = false) {
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "Fiatlookup",
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          AltCoinID: this.record.AltCoinID,
          Amount: 1,
          FiatLookup: 0,
          FiatMoney: this.selectedInputCurrency,
          output: "json",
        },
      }).then(response => {
        if (response.data && response.data.length > 0) {
          this.fiatData = response.data[0];
          if (isCalculate) {
            this.calculate();
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    onSubmit() {
      this.isLoading = true;
      const payload = {
        Call: "SWAP",
        MerchantID: this.record.MerchantID,
        APIKey: this.record.APIKey,
        AltCoinID: this.record.AltCoinID,
        TargetAltCoinID: this.form.AltCoinID,
        Amount: toFixed(this.form.Amount),
        output: "json",
      };
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: payload,
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (typeof response.data === "string" && response.data.toLowerCase() === "ok") {
          this.$toast.fire("", `${this.form.Amount} ${this.record.LongName} swapped successfully`, "success");
          saveNotification(`${this.form.Amount} ${this.record.LongName} swapped successfully`);
          this.close();
        } else {
          this.$toast.fire("", response.data, "error");
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalPayoutSwap").modal("hide");
    },
  },
  mounted() {
    this.fetchInputCurrencies();
    this.$bus.$on("payoutSwapModal", (args) => {
      this.selectedAltCoinID = args.selectedCoin;
      this.coins = args.coins;
      this.record = args.record;
      this.amt = "";
      $("#bsModalPayoutSwap").modal("show");
      this.form = {
        AltCoinID: this.selectedAltCoinID,
        Amount: this.record.Balance,
      };
      this.fiatLookup();
    });
  },
};
</script>
<style lang="scss" scoped>
.justify-between {
  justify-content: space-between;
}
</style>
