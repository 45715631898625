import { render, staticRenderFns } from "./CancelledPayouts.vue?vue&type=template&id=21714df0&scoped=true&"
import script from "./CancelledPayouts.vue?vue&type=script&lang=js&"
export * from "./CancelledPayouts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21714df0",
  null
  
)

export default component.exports