<template>
  <div>
    <div class="text-right mb-8 mr-16">
      <p v-if="pendingPayouts.length" class="text-right text-bold">Total Pending Amount:
        {{ toFixed(pendingPayoutsTotal, 2) + ' EUR' }}
      </p>
      <router-link v-if="merchantID" :to="{ name: 'payouts' }" class="btn btn-default btn-flat btn-sm" tag="a">Back
      </router-link>
    </div>
    <div v-if="pendingPayouts && pendingPayouts.length" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
    <div class="table-responsive">
      <table class="table table-condensed table-striped">
        <thead>
          <tr>
            <th></th>
            <th class="pointer" @click="sort('ID')">
              Payout ID
              <span v-if="sortKey === 'ID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('MerchantID')">
              Merchant ID
              <span v-if="sortKey === 'MerchantID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('OwnerNickName')">
              Owner
              <span v-if="sortKey === 'OwnerNickName'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="no-wrap pointer" @click="sort('ShopTitle')">
              Shop
              <span v-if="sortKey === 'ShopTitle'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('FinalVerification')">
              Status
              <span v-if="sortKey === 'FinalVerification'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" style="width: 170px;min-width: 170px;" @click="sort('PayoutSchema')">
              Payout Schema Used
              <span v-if="sortKey === 'PayoutSchema'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Buy')">
              Buy Enabled
              <span v-if="sortKey === 'Buy'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('SWAPPreferredCoinID')">
              Auto Swap
              <span v-if="sortKey === 'SWAPPreferredCoinID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Amount')">
              Balance
              <span v-if="sortKey === 'Amount'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('AmountFiatValue')">
              Fiat Amount
              <span v-if="sortKey === 'AmountFiatValue'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th></th>
            <th class="text-right">
              Rate
            </th>
            <th class="pointer" @click="sort('CreatedOn')">
              Created On
              <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pr-16">Payout Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="pendingPayouts.length > 0">
            <template v-for="(payout, index) in pendingPayouts">
              <tr :key="index" :class="{ 'bg-light-red': isRefund(payout) }">
                <td>
                  <span class="flex pointer text-success" @click="payout.isExpanded = !payout.isExpanded">
                    <svg v-if="!payout.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <svg v-if="payout.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2"></path>
                    </svg>
                  </span>
                </td>
                <td class="no-wrap">
                  <router-link
                    :to="{ name: 'transaction-detail', params: { id: 'P' + payout.ID.toString().padStart(9, '0') } }"
                    tag="a">{{ payout.ID }}
                  </router-link>
                  <span class="ml-8">
                    <i class="fa fa-clipboard pointer"
                      @click="copyToClipboard('P' + payout.ID.toString().padStart(9, '0'))"></i>
                  </span>
                </td>
                <td>
                  <router-link :to="{ name: 'payouts', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.MerchantID }}
                  </router-link>
                </td>
                <td class="no-wrap">
                  <router-link :to="{ name: 'user-detail', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.OwnerNickName }}
                  </router-link>
                </td>
                <td class="no-wrap">
                  <router-link :to="{ name: 'user-detail', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.ShopTitle }}
                  </router-link>
                </td>
                <td v-html="formatMerchantStatus(payout.FinalVerification)"></td>
                <td>
                  {{ isPayoutType(payout) }}
                </td>
                <td v-html="formatStatus(payout.Buy, '60px')"></td>
                <td>
                  <label v-if="payout.AutoSwap" class="label label-success mb-0">
                    {{ getCoinName(payout.PreferredCoinID) }}</label>
                  <label v-else class="label label-danger mb-0">disabled</label>
                </td>
                <td class="no-wrap">
                  <span class="flex align-items-center">
                    <span class="mr-8">
                      <i class="fa fa-copy pointer" @click="toConverter(payout.Balance, payout.AltCoinID)"></i>
                    </span>
                    <router-link :to="{ name: 'coin-health-detail', params: { id: payout.AltCoinID } }" tag="a">
                      <img :src="generateIconUrl(payout)" alt="" class="w-24 mr-8" />
                    </router-link>
                    <router-link tag="a" :to="{ name: 'payouts', query: { id: payout.AltCoinID } }">
                      {{ toFixed(payout.Balance) + ' ' + payout.LongName }}
                    </router-link>
                  </span>
                </td>
                <td class="no-wrap">
                  <span class="flex align-items-center">
                    <span class="mr-8">
                      <i class="fa fa-copy pointer" @click="toConverter(toFixed(payout.AmountFiatValue, 2), 3)"></i>
                    </span>
                    {{ toFixed(payout.AmountFiatValue, 2) + ' EUR' }}
                  </span>
                </td>
                <td>
                  <img v-show="isRefund(payout)" style="width: 24px;" src="@/assets/images/refund.png" alt="refund" />
                </td>
                <td class="no-wrap text-right">{{ toFixed(payout.AmountFiatValue / payout.Balance) }}</td>
                <td class="no-wrap">{{ formatDate(payout.CreatedOn) }}</td>
                <td class="flex align-items-center pr-16">
                  <button v-tooltip="{ content: 'Cancel Payout', trigger: 'click hover focus' }"
                    class="btn btn-danger btn-sm btn-flat mr-8" @click="cancel(payout, index)">Cancel
                  </button>
                  <button v-if="payout.IsExcluded"
                    v-tooltip="{ content: 'Customer has excluded this currency from daily payouts', trigger: 'click hover focus' }"
                    class="btn btn-sm btn-svg mr-8">
                    <svg class="w-24 h-24 text-yellow pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                  </button>
                  <button v-tooltip="{ content: 'Set to Payout completed', trigger: 'click hover focus' }"
                    class="btn btn-primary btn-flat btn-sm mr-8" @click="manualPaid(payout, index)">Manual Paid
                  </button>
                </td>
              </tr>
              <template v-if="payout.isExpanded">
                <tr :key="'detail_' + index">
                  <td class="px-15" colspan="14">
                    <div class="row">
                      <div class="col-md-6">
                        <table class="table table-condensed table-striped mb-0">
                          <tbody>
                            <tr>
                              <th colspan="2">Payout Information</th>
                            </tr>
                            <template v-if="isPayoutType(payout) === 'Bank'">
                              <tr v-if="payout.PayoutName">
                                <td>Account Holder Name</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.PayoutName)"></i>
                                  </span>
                                  {{ payout.PayoutName }}
                                </td>
                              </tr>
                              <tr v-if="payout.PayoutIBAN">
                                <td>IBAN/Account Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.PayoutIBAN)"></i>
                                  </span>
                                  {{ payout.PayoutIBAN }}
                                </td>
                              </tr>
                              <tr v-if="payout.AccountType">
                                <td>Account Type</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.AccountType)"></i>
                                  </span>
                                  {{ payout.AccountType.toLowerCase() }}
                                </td>
                              </tr>
                              <tr v-if="payout.TaxId">
                                <td>Tax Id: CUIL / CUIT</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.TaxId)"></i>
                                  </span>
                                  {{ payout.TaxId }}
                                </td>
                              </tr>
                              <tr v-if="payout.BsbCode">
                                <td>BSC Code</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.BsbCode)"></i>
                                  </span>
                                  {{ payout.BsbCode }}
                                </td>
                              </tr>
                              <tr v-if="payout.PayoutBIC">
                                <td>BIC/Swift/Routing code</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.PayoutBIC)"></i>
                                  </span>
                                  {{ payout.PayoutBIC }}
                                </td>
                              </tr>
                              <tr v-if="payout.BranchCode">
                                <td>Branch Code</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.BranchCode)"></i>
                                  </span>
                                  {{ payout.BranchCode }}
                                </td>
                              </tr>
                              <tr v-if="payout.CPF">
                                <td>Recipient tax ID/CPF</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.CPF)"></i>
                                  </span>
                                  {{ payout.CPF }}
                                </td>
                              </tr>
                              <tr v-if="payout.TransitNumber">
                                <td>Transit Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.TransitNumber)"></i>
                                  </span>
                                  {{ payout.TransitNumber }}
                                </td>
                              </tr>
                              <tr v-if="payout.PhoneNumber">
                                <td>Account Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.PhoneNumber)"></i>
                                  </span>
                                  {{ payout.PhoneNumber }}
                                </td>
                              </tr>
                              <tr v-if="payout.InstitutionNumber">
                                <td>Institution Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer"
                                      @click="copyToClipboard(payout.InstitutionNumber)"></i>
                                  </span>
                                  {{ payout.InstitutionNumber }}
                                </td>
                              </tr>
                              <tr v-if="payout.TransitCode">
                                <td>Transit Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.TransitCode)"></i>
                                  </span>
                                  {{ payout.TransitCode }}
                                </td>
                              </tr>
                              <tr v-if="payout.RUT">
                                <td>Beneficiary's RUT number (Rol Único Tributario)</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.RUT)"></i>
                                  </span>
                                  {{ payout.RUT }}
                                </td>
                              </tr>
                              <tr v-if="payout.CardNumber">
                                <td>UnionPay card number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.CardNumber)"></i>
                                  </span>
                                  {{ payout.CardNumber }}
                                </td>
                              </tr>
                              <tr v-if="payout.SortCode">
                                <td>Sort Code</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.SortCode)"></i>
                                  </span>
                                  {{ payout.SortCode }}
                                </td>
                              </tr>
                              <tr v-if="payout.IfscCode">
                                <td>IFSC Code</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.IfscCode)"></i>
                                  </span>
                                  {{ payout.IfscCode }}
                                </td>
                              </tr>
                              <tr v-if="payout.Clabe">
                                <td>Recipient CLABE Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.Clabe)"></i>
                                  </span>
                                  {{ payout.Clabe }}
                                </td>
                              </tr>
                              <tr v-if="payout.SwiftCode">
                                <td>Swift Code</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.SwiftCode)"></i>
                                  </span>
                                  {{ payout.SwiftCode }}
                                </td>
                              </tr>
                              <tr v-if="payout.IdDocumentType">
                                <td>ID Document Type</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer"
                                      @click="copyToClipboard(payout.IdDocumentType)"></i>
                                  </span>
                                  {{ payout.IdDocumentType }}
                                </td>
                              </tr>
                              <tr v-if="payout.IdDocumentNumber">
                                <td>Identification Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer"
                                      @click="copyToClipboard(payout.IdDocumentNumber)"></i>
                                  </span>
                                  {{ payout.IdDocumentNumber }}
                                </td>
                              </tr>
                              <tr v-if="payout.Abartn">
                                <td>Routing Number</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.Abartn)"></i>
                                  </span>
                                  {{ payout.Abartn }}
                                </td>
                              </tr>
                              <tr v-if="payout.FirstLine">
                                <td>Address</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.FirstLine)"></i>
                                  </span>
                                  {{ payout.FirstLine }}
                                </td>
                              </tr>
                              <tr v-if="payout.City">
                                <td>City</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.City)"></i>
                                  </span>
                                  {{ payout.City }}
                                </td>
                              </tr>
                              <tr v-if="payout.State">
                                <td>State</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.State)"></i>
                                  </span>
                                  {{ payout.State }}
                                </td>
                              </tr>
                              <tr v-if="payout.PostCode">
                                <td>Postal Code</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.PostCode)"></i>
                                  </span>
                                  {{ payout.PostCode }}
                                </td>
                              </tr>
                              <tr v-if="payout.Country">
                                <td>Country</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.Country)"></i>
                                  </span>
                                  {{ payout.Country }}
                                </td>
                              </tr>
                            </template>
                            <template v-if="isPayoutType(payout) === 'Payeer'">
                              <tr v-if="payout.PayoutTo">
                                <td>Payeer</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.PayoutTo)"></i>
                                  </span>
                                  {{ payout.PayoutTo }}
                                </td>
                              </tr>
                            </template>
                            <template v-if="isPayoutType(payout) === 'PayPal'">
                              <tr v-if="payout.PayoutTo">
                                <td>PayPal</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.PayoutTo)"></i>
                                  </span>
                                  {{ payout.PayoutTo }}
                                </td>
                              </tr>
                            </template>
                            <template v-if="isPayoutType(payout) === 'Crypto'">
                              <tr>
                                <td class="no-wrap">Crypto Currency</td>
                                <td>
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer"
                                      @click="copyToClipboard(payout.CoinName + ' - ' + payout.LongName)"></i>
                                  </span>
                                  {{ payout.CoinName + ' - ' + payout.LongName }}
                                </td>
                              </tr>
                              <tr>
                                <td>Address</td>
                                <td style="word-break: break-all;">
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer"
                                      @click="copyToClipboard(payout.CoinAddressUsed)"></i>
                                  </span>
                                  {{ payout.CoinAddressUsed }}
                                </td>
                              </tr>
                              <tr v-if="payout.Tag">
                                <td>Tag</td>
                                <td style="word-break: break-all;">
                                  <span class="mr-8">
                                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.Tag)"></i>
                                  </span>
                                  {{ payout.Tag }}
                                </td>
                              </tr>
                            </template>
                            <tr>
                              <td class="no-wrap">Amount Fiat Value</td>
                              <td>
                                <span class="mr-8">
                                  <i class="fa fa-clipboard pointer"
                                    @click="copyToClipboard(toFixed(payout.AmountFiatValue))"></i>
                                </span>
                                {{ toFixed(payout.AmountFiatValue, 2) + ' EUR' }}
                              </td>
                            </tr>
                            <tr>
                              <td>Target Currency</td>
                              <td>
                                <span class="mr-8">
                                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.TargetCurrency)"></i>
                                </span>
                                {{ payout.TargetCurrency }}
                              </td>
                            </tr>
                            <tr v-if="payout.CustomerReferenceNr">
                              <td>CustomerReferenceNr</td>
                              <td>
                                <span class="mr-8">
                                  <i class="fa fa-clipboard pointer"
                                    @click="copyToClipboard(payout.CustomerReferenceNr)"></i>
                                </span>
                                {{ payout.CustomerReferenceNr }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-6">
                        <a :href="'https://cointopay.com/CsvExportAdmin.jsp?type=1&AltCoinID=' + payout.AltCoinID + '&MerchantID=' + payout.MerchantID"
                          class="mr-8" target="_blank">
                          <img alt="" class="w-48 h-48" src="/assets/img/csv.svg" />
                        </a>
                        <a :href="'https://cointopay.com/CsvExportAdmin.jsp?type=2&AltCoinID=' + payout.AltCoinID + '&MerchantID=' + payout.MerchantID"
                          target="_blank">
                          <img alt="" class="w-48 h-48" src="/assets/img/csv.svg" />
                        </a>
                        <div class="form-group mt-8">
                          <label for="comments">
                            Comments
                            <span class="ml-8">
                              <i class="fa fa-clipboard pointer" @click="copyToClipboard(payout.Comments)"></i>
                            </span>
                          </label>
                          <textarea id="comments" v-model="payout.Comments" class="form-control" readonly
                            rows="5"></textarea>
                        </div>
                        <div class="text-right">
                          <button class="btn btn-primary btn-flat btn-sm" @click="launchUpdateCommentsModal(payout)">
                            Update Comments
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
          <template v-else>
            <tr>
              <td colspan="15">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="pendingPayouts && pendingPayouts.length" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  formatDate,
  formatMerchantStatus,
  formatStatus,
  getPayoutSchemaType,
  logout,
  payoutSchemas,
  toFixed,
  saveNotification,
  generateIconUrl,
} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import { SAVE_CONVERTER } from "@/store/keys";

export default {
  name: "PendingPayouts",
  components: {
    Pagination,
  },
  props: {
    payouts: {
      type: Array,
      default() {
        return [];
      },
    },
    meta: {
      type: Object,
      default() {
        return {
          total: 0,
          page: 1,
          offset: 0,
          limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
        };
      },
    },
    searchOrder: {
      type: String,
      default: "DESC",
    },
    searchSortKey: {
      type: String,
      default: "ID",
    },
  },
  data() {
    return {
      payoutSchemas: payoutSchemas,
      merchantID: "",
      keyword: "",
      pendingPayoutsTotal: 0,
      sortKey: "ID",
      order: "desc",
      pendingPayouts: this.payouts,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "jwtToken",
      "altCoins",
      "converter",
    ]),
  },
  watch: {
    payouts: {
      handler(val) {
        this.getPendingPayoutsTotal();
        this.pendingPayouts = val;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isRefund(payout) {
      return payout.CustomerReferenceNr && payout.CustomerReferenceNr.startsWith("refund-");
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.$emit("search", {
        order: this.order,
        sortKey: this.sortKey,
      });
    },
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    startLoader() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoader() {
      this.$swal.close();
    },
    toFixed(val, decimal = 8) {
      return toFixed(val, decimal);
    },
    formatStatus(status, maxWidth) {
      return formatStatus(status, maxWidth);
    },
    formatMerchantStatus(status) {
      return formatMerchantStatus(status);
    },
    formatDate(val) {
      return formatDate(val);
    },
    getCoinName(id) {
      const coin = this._.find(this.altCoins, { ID: id });
      return coin ? coin.Name : id;
    },
    updatePage(page) {
      this.$emit("updatePage", page);
    },
    payoutSchemaToSlug(schema) {
      const payoutSchema = this._.find(this.payoutSchemas, { title: schema });
      if (payoutSchema) {
        schema = payoutSchema.slug;
      }
      return schema;
    },
    getPayoutSchemaType(payoutSchema) {
      return getPayoutSchemaType(payoutSchema);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    getPendingPayoutsTotal() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=GetPendingPayoutsTotal`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        if (response.data.statusCode === 200) {
          this.pendingPayoutsTotal = response.data.data;
        }
      }).catch(error => {
        this.$toast.fire("", error.response.message, "erorr");
      });
    },
    // Actions
    cancel(record, index) {
      this.$swal.fire({
        title: "Cancel Payout Request",
        text: "Are you sure, want to cancel",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "CancelPayout",
            MerchantID: this.currentUser.ID,
            APIKey: this.currentUser.APIKey,
            targetMerchant: record.MerchantID,
            PayoutID: record.ID,
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", "Payout cancelled successfully", "success");
                saveNotification("Payout cancelled successfully");
                this.pendingPayouts.splice(index, 1);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            this.$swal.close();
            console.log(error);
          });
        }
      });
    },
    isPayoutType(payout) {
      let type = "Crypto";
      const payoutType = Number.parseInt(payout.PayoutType);
      if (payoutType === 11) {
        type = "PayPal";
      } else if (payoutType === 17) {
        type = "Payeer";
      } else if (payout.isBankPayout) {
        type = "Bank";
      }
      return type;
    },
    manualPaid(payout, index) {
      this.$bus.$emit("update-transaction-logs-modal", {
        title: "Update Transaction Logs",
        data: {
          TransactionID: payout.ID,
          Prefix: "P",
          TransactionType: payout.PayoutTypeName,
          ExternalTransactionProvider: "",
          Comments: payout.Comments,
          MerchantID: this.currentUser.ID,
        },
        confirm: (comments, paymentProviderID) => {
          const data = this.payoutTypes;
          const pp = this._.find(data, { ID: paymentProviderID });
          if (pp) {
            const index = this._.findIndex(this.pendingPayouts, { ID: payout.ID });
            if (index !== -1) {
              payout = Object.assign({}, payout, {
                PayoutTypeName: pp.Name,
                PayoutType: pp.ID.toString(),
                Comments: comments,
              });
              this.pendingPayouts.splice(index, 1, payout);
            }
          }
          this.startLoader();
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: {
              Call: "FiatPayoutStatusUpdater",
              MerchantID: 1,
              payoutid: payout.ID,
              APIKey: "_",
              output: "json",
            },
          }).then(response => {
            this.stopLoader();
            if (response.data.status_code === 200) {
              this.updatePage(this.meta.page);
              setTimeout(() => {
                this.$toast.fire("", response.data.message, "success");
                saveNotification(response.data.message);
              }, 500);
            } else {
              this.$toast.fire("", response.data.message, "error");
              if (response.data.message.toLowerCase() === "no merchant session") {
                logout();
              }
            }
          }).catch(error => {
            this.stopLoader();
            console.log(error);
          });
        },
      });
    },
    launchUpdateCommentsModal(payout, index) {
      this.$bus.$emit("update-transaction-logs-modal", {
        title: "Update Comments",
        data: {
          TransactionID: payout.ID,
          MerchantID: payout.MerchantID,
          Comments: payout.Comments,
          Prefix: "P",
        },
        confirm: (comments) => {
          payout = Object.assign({}, payout, {
            Comments: comments,
          });
          this.pendingPayouts.splice(index, 1, payout);
        },
      });
    },
    generateIconUrl(record) {
      return generateIconUrl(record.CoinName);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.merchantID = this.$route.params.id;
    } else {
      this.merchantID = "";
    }
    this.order = this.searchOrder;
    this.sortKey = this.searchSortKey;
  },
};
</script>

<style scoped lang="scss"></style>
