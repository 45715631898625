<template>
  <div>
    <div class="flex align-items-center justify-end mb-8 mr-8">
      <span v-if="searching" class="text-bold text-danger">{{ searching }}</span>
      <input v-model="keyword" aria-label="Search" class="form-control ml-8" placeholder="Search..." type="text"
        style="width: 200px" @keyup="searchCancelledPayouts()" />
      <router-link v-if="merchantID" :to="{ name: 'payouts' }" class="btn btn-default btn-flat btn-sm ml-8" tag="a">Back
      </router-link>
    </div>
    <div v-if="cancelledPayouts && cancelledPayouts.length" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
    <div class="table-responsive">
      <table class="table table-condensed table-striped">
        <thead>
          <tr>
            <th></th>
            <th class="pointer" @click="sort('ID')">
              Payout ID
              <span v-if="sortKey === 'ID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('MerchantID')">
              Merchant ID
              <span v-if="sortKey === 'MerchantID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('OwnerNickName')">
              Owner
              <span v-if="sortKey === 'OwnerNickName'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="no-wrap pointer" @click="sort('ShopTitle')">
              Shop
              <span v-if="sortKey === 'ShopTitle'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('FinalVerification')">
              Status
              <span v-if="sortKey === 'FinalVerification'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="text-right pointer" style="width: 170px;min-width: 170px;" @click="sort('PayoutSchema')">
              Payout Schema
              <span v-if="sortKey === 'PayoutSchema'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Buy')">
              Buy Enabled
              <span v-if="sortKey === 'Buy'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('SWAPPreferredCoinID')">
              Auto Swap
              <span v-if="sortKey === 'SWAPPreferredCoinID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer text-right" @click="sort('Amount')">
              Balance
              <span v-if="sortKey === 'Amount'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('CreatedOn')">
              Created On
              <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer pr-16" @click="sort('UpdateOn')">
              Update On
              <span v-if="sortKey === 'UpdateOn'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="cancelledPayouts.length > 0">
            <template v-for="(payout, index) in cancelledPayouts">
              <tr :key="index">
                <td>
                  <span class="flex pointer text-success" @click="payout.isExpanded = !payout.isExpanded">
                    <svg v-if="!payout.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <svg v-if="payout.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2"></path>
                    </svg>
                  </span>
                </td>
                <td>
                  <span class="flex align-items-center">
                    <router-link
                      :to="{ name: 'transaction-detail', params: { id: 'P' + payout.ID.toString().padStart(9, '0') } }"
                      tag="a">{{ payout.ID }}
                    </router-link>
                    <span class="ml-8">
                      <i class="fa fa-copy pointer" @click="copyToClipboard(payout.ID.toString())"></i>
                    </span>
                  </span>
                </td>
                <td>
                  <router-link :to="{ name: 'payouts', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.MerchantID }}
                  </router-link>
                </td>
                <td class="no-wrap">
                  <router-link :to="{ name: 'user-detail', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.OwnerNickName }}
                  </router-link>
                </td>
                <td class="no-wrap">
                  <router-link :to="{ name: 'user-detail', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.ShopTitle }}
                  </router-link>
                </td>
                <td v-html="formatMerchantStatus(payout.FinalVerification)"></td>
                <td class="text-right no-wrap">
                  <span class="mr-8">{{ isPayoutType(payout) }}</span>
                </td>
                <td v-html="formatStatus(payout.Buy, '60px')"></td>
                <td>
                  <label v-if="payout.AutoSwap" class="label label-success mb-0">
                    {{ getCoinName(payout.PreferredCoinID) }}</label>
                  <label v-else class="label label-danger mb-0">disabled</label>
                </td>
                <td class="text-right">
                  <router-link tag="a" :to="{ name: 'payouts', query: { id: payout.AltCoinID } }">
                    {{ toFixed(payout.Balance) + ' ' + payout.LongName }}
                  </router-link>
                </td>
                <td>{{ formatDate(payout.CreatedOn) }}</td>
                <td class="pr-16">{{ formatDate(payout.UpdateOn) }}</td>
              </tr>
              <template v-if="payout.isExpanded">
                <tr :key="'detail_' + index">
                  <td class="px-15" colspan="10">
                    <div class="row">
                      <div class="col-md-6">
                        <table class="table table-condensed table-striped mb-0">
                          <tbody>
                            <tr>
                              <th colspan="2">Payout Information</th>
                            </tr>
                            <template v-if="isPayoutType(payout) === 'Bank'">
                              <tr v-if="payout.PayoutName">
                                <td>Account Holder Name</td>
                                <td>{{ payout.PayoutName }}</td>
                              </tr>
                              <tr v-if="payout.PayoutIBAN">
                                <td>IBAN/Account Number</td>
                                <td>{{ payout.PayoutIBAN }}</td>
                              </tr>
                              <tr v-if="payout.AccountType">
                                <td>Account Type</td>
                                <td>{{ payout.AccountType.toLowerCase() }}</td>
                              </tr>
                              <tr v-if="payout.TaxId">
                                <td>Tax Id: CUIL / CUIT</td>
                                <td>{{ payout.TaxId }}</td>
                              </tr>
                              <tr v-if="payout.BsbCode">
                                <td>BSC Code</td>
                                <td>{{ payout.BsbCode }}</td>
                              </tr>
                              <tr v-if="payout.PayoutBIC">
                                <td>BIC/Swift/Routing code</td>
                                <td>{{ payout.PayoutBIC }}</td>
                              </tr>
                              <tr v-if="payout.BranchCode">
                                <td>Branch Code</td>
                                <td>{{ payout.BranchCode }}</td>
                              </tr>
                              <tr v-if="payout.CPF">
                                <td>Recipient tax ID/CPF</td>
                                <td>{{ payout.CPF }}</td>
                              </tr>
                              <tr v-if="payout.PhoneNumber">
                                <td>Account Number</td>
                                <td>{{ payout.PhoneNumber }}</td>
                              </tr>
                              <tr v-if="payout.InstitutionNumber">
                                <td>Institution Number</td>
                                <td>{{ payout.InstitutionNumber }}</td>
                              </tr>
                              <tr v-if="payout.TransitCode">
                                <td>Transit Number</td>
                                <td>{{ payout.TransitCode }}</td>
                              </tr>
                              <tr v-if="payout.RUT">
                                <td>Beneficiary's RUT number (Rol Único Tributario)</td>
                                <td>{{ payout.RUT }}</td>
                              </tr>
                              <tr v-if="payout.CardNumber">
                                <td>UnionPay card number</td>
                                <td>{{ payout.CardNumber }}</td>
                              </tr>
                              <tr v-if="payout.SortCode">
                                <td>Sort Code</td>
                                <td>{{ payout.SortCode }}</td>
                              </tr>
                              <tr v-if="payout.IfscCode">
                                <td>IFSC Code</td>
                                <td>{{ payout.IfscCode }}</td>
                              </tr>
                              <tr v-if="payout.Clabe">
                                <td>Recipient CLABE Number</td>
                                <td>{{ payout.Clabe }}</td>
                              </tr>
                              <tr v-if="payout.SwiftCode">
                                <td>Swift Code</td>
                                <td>{{ payout.SwiftCode }}</td>
                              </tr>
                              <tr v-if="payout.IdDocumentType">
                                <td>ID Document Type</td>
                                <td>{{ payout.IdDocumentType }}</td>
                              </tr>
                              <tr v-if="payout.IdDocumentNumber">
                                <td>Identification Number</td>
                                <td>{{ payout.IdDocumentNumber }}</td>
                              </tr>
                              <tr v-if="payout.Abartn">
                                <td>Routing Number</td>
                                <td>{{ payout.Abartn }}</td>
                              </tr>
                              <tr v-if="payout.FirstLine">
                                <td>Address</td>
                                <td>{{ payout.FirstLine }}</td>
                              </tr>
                              <tr v-if="payout.City">
                                <td>City</td>
                                <td>{{ payout.City }}</td>
                              </tr>
                              <tr v-if="payout.State">
                                <td>State</td>
                                <td>{{ payout.State }}</td>
                              </tr>
                              <tr v-if="payout.PostCode">
                                <td>Postal Code</td>
                                <td>{{ payout.PostCode }}</td>
                              </tr>
                              <tr v-if="payout.Country">
                                <td>Country</td>
                                <td>{{ payout.Country }}</td>
                              </tr>
                            </template>
                            <template v-if="isPayoutType(payout) === 'Payeer'">
                              <tr v-if="payout.PayoutTo">
                                <td>Paypal/Payeer</td>
                                <td>{{ payout.PayoutTo }}</td>
                              </tr>
                            </template>
                            <template v-if="isPayoutType(payout) === 'Crypto'">
                              <tr>
                                <td>Crypto Currency</td>
                                <td>{{ payout.CoinName + ' - ' + payout.LongName }}</td>
                              </tr>
                              <tr>
                                <td>Address</td>
                                <td>{{ payout.CoinAddressUsed }}</td>
                              </tr>
                            </template>
                            <template v-if="isPayoutType(payout) === 'Not Available'">
                              <tr>
                                <td colspan="2">Not Available</td>
                              </tr>
                            </template>
                            <tr>
                              <td class="no-wrap">Amount Fiat Value</td>
                              <td>{{ toFixed(payout.AmountFiatValue) + ' EUR' }}</td>
                            </tr>
                            <tr>
                              <td>Target Currency</td>
                              <td>{{ payout.TargetCurrency }}</td>
                            </tr>
                            <tr v-if="payout.CustomerReferenceNr">
                              <td>CustomerReferenceNr</td>
                              <td>{{ payout.CustomerReferenceNr }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-6">
                        <a :href="'https://cointopay.com/CsvExportAdmin.jsp?type=1&AltCoinID=' + payout.AltCoinID + '&MerchantID=' + payout.MerchantID"
                          class="mr-8" target="_blank">
                          <img alt="" class="w-48 h-48" src="/assets/img/csv.svg" />
                        </a>
                        <a :href="'https://cointopay.com/CsvExportAdmin.jsp?type=2&AltCoinID=' + payout.AltCoinID + '&MerchantID=' + payout.MerchantID"
                          target="_blank">
                          <img alt="" class="w-48 h-48" src="/assets/img/csv.svg" />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
          <template v-else>
            <tr>
              <td colspan="12">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="cancelledPayouts && cancelledPayouts.length" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  formatDate,
  formatMerchantStatus,
  formatStatus,
  getPayoutSchemaType,
  payoutSchemas,
  toFixed,
  saveNotification,
} from "@/lib/utils";
import Pagination from "@/components/Pagination";

export default {
  name: "CancelledPayouts",
  components: {
    Pagination,
  },
  props: {
    payouts: {
      type: Array,
      default() {
        return [];
      },
    },
    meta: {
      type: Object,
      default() {
        return {
          total: 0,
          page: 1,
          offset: 0,
          limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
        };
      },
    },
    searchKeyword: {
      type: String,
      default: "",
    },
    searchOrder: {
      type: String,
      default: "DESC",
    },
    searchSortKey: {
      type: String,
      default: "UpdateOn",
    },
  },
  data() {
    return {
      payoutSchemas: payoutSchemas,
      merchantID: "",
      keyword: "",
      searching: "",
      sortKey: "UpdateOn",
      order: "desc",
      cancelledPayouts: this.payouts,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "jwtToken",
      "altCoins",
    ]),
  },
  watch: {
    payouts: {
      handler(val) {
        this.searching = "";
        this.cancelledPayouts = val;
      },
      deep: true,
    },
  },
  methods: {
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.$emit("search", {
        order: this.order,
        sortKey: this.sortKey,
      });
    },
    toFixed(val) {
      return toFixed(val);
    },
    formatStatus(status, maxWidth) {
      return formatStatus(status, maxWidth);
    },
    formatMerchantStatus(status) {
      return formatMerchantStatus(status);
    },
    formatDate(val) {
      return formatDate(val);
    },
    getCoinName(id) {
      const coin = this._.find(this.altCoins, { ID: id });
      return coin ? coin.Name : id;
    },
    updatePage(page) {
      this.$emit("updatePage", page);
    },
    payoutSchemaToSlug(schema) {
      const payoutSchema = this._.find(this.payoutSchemas, { title: schema });
      if (payoutSchema) {
        schema = payoutSchema.slug;
      }
      return schema;
    },
    getPayoutSchemaType(payoutSchema) {
      return getPayoutSchemaType(payoutSchema);
    },
    isPayoutType(payout) {
      let type = "Not Available";
      if (payout.CoinAddressUsed && payout.CoinAddressUsed.length > 2) {
        type = "Crypto";
      } else if (payout.PayoutTo) {
        type = "Payeer";
      } else if (payout.PayoutName) {
        type = "Bank";
      }
      return type;
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    searchCancelledPayouts() {
      this.searching = "Searching...";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.$emit("search", {
          keyword: this.keyword,
          order: this.order,
          sortKey: this.sortKey,
          merchantID: this.merchantID,
        });
      }, 500);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.merchantID = this.$route.params.id;
    } else {
      this.merchantID = "";
    }
    this.keyword = this.searchKeyword ? this.searchKeyword.trim() : "";
    this.order = this.searchOrder;
    this.sortKey = this.searchSortKey;
  },
};
</script>

<style scoped></style>
