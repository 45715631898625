<template>
  <div id="bsModalCreateTransaction" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <template v-if="transaction === ''">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                  <label for="ct-amount">Amount</label>
                  <input id="ct-amount" v-model="form.Amount" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="merchant id" rules="required"
                                    tag="div">
                  <label for="ct-merchantID">Merchant</label>
                  <select id="ct-merchantID" v-model="form.MerchantID" class="form-control" @change="populate()">
                    <option v-for="(merchant, index) in merchants" :key="index"
                            :value="merchant.id">{{ merchant.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="api key" rules="required" tag="div">
                  <label for="ct-apiKey">API Key</label>
                  <input id="ct-apiKey" v-model="form.APIKey" class="form-control" readonly type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="altCoinID" rules="required" tag="div">
                  <label for="ct-altCoinID">AltCoinID</label>
                  <select id="ct-altCoinID" v-model="form.AltCoinID" class="form-control">
                    <option v-for="(altCoin, index) in merchantAltCoins" :key="index" :value="altCoin.ID">
                      {{ altCoin.Name + ' - ' + altCoin.LongName }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="input currency" rules="required"
                                    tag="div">
                  <label for="ct-inputCurrency">Input Currency</label>
                  <select id="ct-inputCurrency" v-model="form.inputCurrency" class="form-control">
                    <option v-for="(inputCurrency, index) in inputCurrencies" :key="index"
                            :value="inputCurrency.ShortName">
                      {{ inputCurrency.Name + ' - ' + inputCurrency.LongName }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="text-right">
                  <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                    <span v-if="!isLoading">Crypto deposit</span>
                    <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
          <template v-else>
            <div v-html="transaction"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification } from "@/lib/utils";

export default {
  name: "CreateTransactionModal",
  data() {
    return {
      isLoading: false,
      title: "",
      merchants: [],
      merchantAltCoins: [],
      inputCurrencies: [],
      transaction: "",
      form: {
        Call: "CreateTransaction",
        MerchantID: "",
        APIKey: "",
        AltCoinID: "",
        inputCurrency: "",
        Amount: "",
        V2: "",
        output: "json",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
    ]),
  },
  methods: {
    confirm() {
      this.confirmHook();
      this.close();
    },
    populate() {
      const payout = this._.find(this.payouts, { MerchantID: this.form.MerchantID });
      if (payout) {
        const selectedAltCoins = payout.SelectedAltCoins.split(",").filter((v, i, a) => a.indexOf(v) === i);
        this.merchantAltCoins = [];
        selectedAltCoins.forEach(id => {
          const coin = this._.find(this.onlineAltCoins, { ID: Number.parseInt(id, 10) });
          if (coin) {
            this.merchantAltCoins.push(coin);
          }
        });
        this.form.APIKey = payout.APIKey;
        this.form.output = "json";
      }
    },
    onSubmit() {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.isLoading = false;
        const result = response.data;
        if (result.result.toLowerCase() === "success") {
          this.reset();
          setTimeout(() => {
            this.transaction = result.message;
          }, 100);
          this.$toast.fire("", "Transaction created successfully", "success");
          saveNotification("Transaction created successfully");
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    reset() {
      this.form = {
        Call: "CreateTransaction",
        MerchantID: "",
        APIKey: "",
        AltCoinID: "",
        inputCurrency: "",
        Amount: "",
        V2: "",
        output: "json",
      };
      this.$nextTick(() => this.$refs.observer.reset());
    },
    close() {
      if (this.transaction === "") {
        this.reset();
      }
      this.transaction = "";
      $("#bsModalCreateTransaction").modal("hide");
    },
  },
  mounted() {
    this.inputCurrencies = this.onlineAltCoins;
    this.$bus.$on("create-transaction-modal", (args) => {
      this.title = args.title;
      this.payouts = args.data;
      if (this.payouts.length > 0) {
        this.merchants = [];
        this.payouts.forEach(payout => {
          const index = this._.findIndex(this.merchants, { id: payout.MerchantID });
          if (index === -1) {
            this.merchants.push({
              id: payout.MerchantID,
              name: payout.ShopTitle,
            });
          }
        });
      }
      this.confirmHook = args.confirm;
      $("#bsModalCreateTransaction").modal("show");
    });
  },
};
</script>

<style scoped></style>
