<template>
  <div>
    <div class="box box-default">
      <div class="box-header">
        <div class="flex justify-between">
          <h3 class="box-title">Payouts</h3>
          <button v-tooltip="{ content: 'Generate fiat payout links', trigger: 'click hover focus' }"
            class="btn btn-info btn-sm btn-flat" @click="generateFiatPayoutLinks()">Send Payout Links
          </button>
        </div>
      </div>
      <div class="box-body no-padding">
        <va-tabs :padding="'padding: 10px 0 0'" @selectedTab="selectedTab">
          <!-- Requested Payouts -->
          <div id="requested" slot="content" :data-title="'Payout Schemas'" class="active">
            <div v-show="loader !== 'requested'">
              <requested-payouts :altCoinIDSearched="altCoinID.toString()" :meta="meta"
                :payoutSchemaSearched="payoutSchema" :payouts="requestedPayouts" :searchKeyword="keyword"
                :searchOrder="order" :searchSortKey="sortKey" @reload="getRequestedPayouts()"
                @reloadRequestPayouts="reloadRequestPayouts()" @search="searchRequestedPayouts"
                @updatePage="updatePage"></requested-payouts>
            </div>
            <va-loader v-show="loader === 'requested'"></va-loader>
          </div>
          <!-- Pending Payouts -->
          <div id="pending" slot="content" :data-title="'Pending Payouts'">
            <div v-show="loader !== 'pending'">
              <pending-payouts :meta="meta" :payouts="pendingPayouts" :searchOrder="order" :searchSortKey="sortKey"
                @search="searchPendingPayouts" @updatePage="updatePage"></pending-payouts>
            </div>
            <va-loader v-show="loader === 'pending'"></va-loader>
          </div>
          <!-- Cancelled Payouts -->
          <div id="cancelled" slot="content" :data-title="'Cancelled Payouts'">
            <div v-show="loader !== 'cancelled'">
              <cancelled-payouts :meta="meta" :payouts="cancelledPayouts" :searchOrder="order" :searchSortKey="sortKey"
                @search="searchCancelledPayouts" :searchKeyword="keyword" @updatePage="updatePage"></cancelled-payouts>
            </div>
            <va-loader v-show="loader === 'cancelled'"></va-loader>
          </div>
          <!-- Completed Payouts -->
          <div id="completed" slot="content" :data-title="'Completed Payouts'">
            <div v-show="loader !== 'completed'">
              <completed-payouts :meta="meta" :payouts="completedPayouts" :merchants="merchants" :searchKeyword="keyword"
                :searchOrder="order" :searchSortKey="sortKey" @search="searchCompletedPayouts"
                @updatePage="updatePage"></completed-payouts>
            </div>
            <va-loader v-show="loader === 'completed'"></va-loader>
          </div>
        </va-tabs>
      </div>
    </div>
    <update-transaction-logs-modal></update-transaction-logs-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VaTabs from "@/components/VATabs";
import VaLoader from "@/components/partials/Loader";
import { formatDataList, getPayoutSchemaType, logout, saveNotification } from "@/lib/utils";
import CancelledPayouts from "@/components/CancelledPayouts";
import RequestedPayouts from "@/components/RequestedPayouts";
import PendingPayouts from "@/components/PendingPayouts";
import CompletedPayouts from "@/components/CompletedPayouts";
import UpdateTransactionLogsModal from "../components/modals/UpdateTransactionLogsModal";

export default {
  name: "Payouts",
  components: {
    UpdateTransactionLogsModal,
    VaTabs,
    VaLoader,
    RequestedPayouts,
    PendingPayouts,
    CancelledPayouts,
    CompletedPayouts,
  },
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_ADMIN_API_URL,
      tab: "requested",
      loader: "",
      keyword: "",
      merchantID: "",
      requestedPayouts: [],
      pendingPayouts: [],
      cancelledPayouts: [],
      completedPayouts: [],
      merchants: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      payoutSchema: "",
      altCoinID: "",
      socket: null,
      isCloseSocket: false,
      order: "DESC",
      sortKey: "MerchantID",
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "jwtToken",
      "altCoins",
    ]),
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        if (this.tab === "requested") {
          const isVerified = this.payoutSchema === "all" ? "" : (this.payoutSchema === "0" ? 0 : 1);
          const payoutSchema = (this.payoutSchema !== "" && this.payoutSchema !== "0") ? this.payoutSchema : "";
          this.getRequestedPayouts(to.params.id, "", payoutSchema, this.altCoinID, isVerified);
        } else if (this.tab === "pending") {
          this.getPendingPayouts(to.params.id);
        } else if (this.tab === "cancelled") {
          this.getCancelledPayouts(to.params.id);
        } else if (this.tab === "completed") {
          this.getCompletedPayouts(to.params.id);
        }
      }
      if (to.query.id) {
        this.tab = "requested";
        this.altCoinID = to.query.id;
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });
        this.payoutSchema = "all";
        this.keyword = "";
        let merchantID = "";
        if (to.params.id) {
          merchantID = to.params.id;
        }
        const isVerified = this.payoutSchema === "all" ? "" : (this.payoutSchema === "0" ? 0 : 1);
        this.getRequestedPayouts(merchantID, this.keyword, this.payoutSchema, this.altCoinID, isVerified);
        $("[href=\"#" + this.tab + "\"]").tab("show");
      }
    },
  },
  methods: {
    resetMeta() {
      this.meta = {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      };
    },
    selectedTab(id) {
      this.tab = id;
      this.expandedRowIndex = 0;
      this.resetMeta();
      this.payoutSchema = "";
      this.altCoinID = "";
      this.keyword = "";
      if (this.$route.params.id) {
        this.$router.replace({
          ...this.$router.currentRoute,
          params: {},
        });
      }
      if (this.tab === "requested") {
        this.getRequestedPayouts();
      } else if (this.tab === "pending") {
        this.getPendingPayouts();
      } else if (this.tab === "cancelled") {
        this.sortKey = "UpdateOn";
        this.order = "DESC";
        this.getCancelledPayouts();
      } else if (this.tab === "completed") {
        this.sortKey = "UpdateOn";
        this.order = "DESC";
        this.getCompletedPayouts();
      }
    },
    searchRequestedPayouts(search) {
      this.resetMeta();
      const isVerified = search.payoutSchema === "all" ? "" : (search.payoutSchema === "0" ? 0 : 1);
      const payoutSchema = (search.payoutSchema !== "" && search.payoutSchema !== "0") ? search.payoutSchema : "";
      this.payoutSchema = search.payoutSchema;
      this.altCoinID = search.altCoinID;
      this.keyword = search.keyword ? search.keyword.trim() : "";
      const merchantID = search.merchantID ? search.merchantID : "";
      this.order = search.order;
      this.sortKey = search.sortKey;
      this.getRequestedPayouts(merchantID, search.keyword, payoutSchema, search.altCoinID, isVerified, this.order, this.sortKey);
    },
    searchPendingPayouts(search) {
      const merchantID = search.merchantID ? search.merchantID : "";
      this.order = search.order;
      this.sortKey = search.sortKey;
      this.getPendingPayouts(merchantID, 1, "", this.order, this.sortKey, true);
    },
    searchCancelledPayouts(search) {
      this.resetMeta();
      this.keyword = search.keyword ? search.keyword.trim() : "";
      this.order = search.order;
      this.sortKey = search.sortKey;
      this.merchantID = search.merchantID;
      this.getCancelledPayouts(this.merchantID, 1, this.keyword, this.order, this.sortKey, true);
    },
    searchCompletedPayouts(search) {
      this.resetMeta();
      this.keyword = search.keyword ? search.keyword.trim() : "";
      this.order = search.order;
      this.sortKey = search.sortKey;
      this.merchantID = search.merchantID;
      this.getCompletedPayouts(search.merchantID, 1, search.keyword, this.order, this.sortKey, false);
    },
    reloadRequestPayouts() {
      this.getRequestedPayouts("", "", "", "", 1, "", "", false);
    },
    getRequestedPayouts(merchantID = "", keyword = "", payoutSchema = "", altCoinID = "", isVerified = 1, order = "DESC", sortKey = "MerchantID", isLoader = true) {
      if (isLoader) {
        this.loader = "requested";
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=PayoutsInfoOnline`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          MerchantID: merchantID,
          Keyword: keyword ? keyword.trim() : "",
          PayoutSchema: payoutSchema,
          IsVerified: isVerified,
          AltCoinID: altCoinID,
          orderBy: order,
          order: sortKey,
        },
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          this.requestedPayouts = response.data.data;
          if (this.requestedPayouts.length > 0) {
            this.requestedPayouts = formatDataList(this.requestedPayouts);
            this.requestedPayouts = this.requestedPayouts.map(p => {
              return {
                ...p,
                isExpanded: false,
              };
            });
            this.meta.total = this.requestedPayouts.length;
          }
        }
      }).catch(error => {
        this.loader = "";
        if (error && error.data && error.data.message) {
          this.$toast.fire("", error.data.message, "error");
          saveNotification(error.data.message);
        }
      });
    },
    getPendingPayouts(merchantID = "", page = 1, keyword = "", order = "DESC", sortKey = "ID", isLoader = true) {
      if (isLoader) {
        this.loader = "pending";
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=PendingPayouts`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          MerchantID: merchantID,
          page,
          keyword,
          limit: this.meta.limit,
          order: sortKey,
          orderBy: order,
        },
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          this.pendingPayouts = response.data.data.payouts;
          if (this.pendingPayouts.length > 0) {
            this.pendingPayouts = formatDataList(this.pendingPayouts);
            this.pendingPayouts = this.pendingPayouts.map(p => {
              return {
                ...p,
                isExpanded: false,
              };
            });
          }
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.loader = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getCancelledPayouts(merchantID = "", page = 1, keyword = "", order = "DESC", sortKey = "UpdateOn", isLoader = true) {
      if (isLoader) {
        this.loader = "cancelled";
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=CancelledPayouts`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          MerchantID: merchantID,
          page,
          keyword,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          this.cancelledPayouts = response.data.data.payouts;
          if (this.cancelledPayouts.length > 0) {
            this.cancelledPayouts = this.cancelledPayouts.map(p => {
              return {
                ...p,
                isExpanded: false,
              };
            });
          }
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.loader = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getCompletedPayouts(merchantID = "", page = 1, keyword = "", order = "DESC", sortKey = "UpdateOn", isLoader = true) {
      if (isLoader) {
        this.loader = "completed";
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=CompletedPayouts`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          MerchantID: merchantID,
          page,
          keyword,
          limit: this.meta.limit,
          order: sortKey,
          orderBy: order,
        },
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          this.completedPayouts = response.data.data.payouts;
          if (this.completedPayouts.length > 0) {
            this.completedPayouts = this.completedPayouts.map(c => {
              return {
                ...c,
                isExpanded: false,
              };
            });
          }
          this.merchants = response.data.data.merchants;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.loader = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getPayoutSchemaType(payoutSchema) {
      return getPayoutSchemaType(payoutSchema);
    },
    updatePage(page) {
      if (this.tab === "requested") {
        this.$nextTick(() => {
          const offset = page > 0 ? page - 1 : 0;
          this.meta = Object.assign({}, this.meta, {
            page: page,
            offset: offset * this.meta.limit,
          });
        });
      } else if (this.tab === "pending") {
        this.getPendingPayouts("", page);
      } else if (this.tab === "cancelled") {
        this.getCancelledPayouts("", page);
      } else if (this.tab === "completed") {
        this.getCompletedPayouts(page.merchantID, page.page, page.keyword);
      }
    },
    generateFiatPayoutLinks() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: {
          AltCoinID: 1,
          FIATPAYOUTLINKS: "",
        },
        withCredentials: true,
      }).then(response => {
        this.$swal.close();
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.$toast.fire("", "Payouts links generated successfully", "success");
            saveNotification("Payouts links generated successfully");
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
      }).catch(error => {
        this.$swal.close();
        console.log(error);
      });
    },
    // Web Socket
    initSocket() {
      this.socket = new WebSocket("wss://wss.cointopay.com/balance");
      const self = this;
      this.socket.onmessage = (event) => {
        if (event) {
          if (event.data) {
            const parts = event.data.split(":");
            if (parts.length >= 4 && this.tab === "requested") {
              const id = Number.parseInt(parts[0], 10);
              if (self._.findIndex(self.requestedPayouts, { MerchantID: id }) !== -1) {
                setTimeout(() => {
                  self.getRequestedPayouts("", "", "", "", 1, "", "", false);
                }, 5000);
              }
            }
          }
        }
      };
      this.socket.onclose = (event) => {
        if (!self.isCloseSocket) {
          self.initSocket();
        }
      };
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.altCoinID = this.$route.query.id;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
      this.payoutSchema = "all";
      this.keyword = "";
    } else {
      this.altCoinID = "";
    }
    let merchantID = "";
    if (this.$route.params.id) {
      merchantID = this.$route.params.id;
    }
    if (this.$route.hash) {
      this.tab = this.$route.hash.replace("#", "");
      setTimeout(() => {
        $("[href=\"#" + this.tab + "\"]").tab("show");
        this.keyword = this.$route.query.keyword;
        if (this.tab === "completed") {
          this.getCompletedPayouts(merchantID, 1, this.keyword);
        } else if (this.tab === "cancelled") {
          this.getCancelledPayouts(merchantID, 1, this.keyword);
        } else if (this.tab === "pending") {
          this.getPendingPayouts(merchantID, 1, this.keyword);
        }
      }, 150);
    } else {
      const isVerified = this.payoutSchema === "all" ? "" : (this.payoutSchema === "0" ? 0 : 1);
      this.getRequestedPayouts(merchantID, this.keyword, this.payoutSchema, this.altCoinID, isVerified);
    }
    this.initSocket();
  },
  beforeDestroy() {
    if (this.socket) {
      this.isCloseSocket = true;
      this.socket.close();
    }
  },
};
</script>

<style scoped></style>
