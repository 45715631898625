<template>
  <div>
    <div class="mb-8 px-15">
      <div class="row">
        <div
          class="flex align-items-center flex-col-sm gap-8 justify-between text-right col-md-12 col-lg-offset-2 col-lg-10">
          <span class="no-wrap text-bold">{{ totalBalance }}</span>
          <span v-if="searching" class="text-bold text-danger">{{ searching }}</span>
          {{ altCoinID }}
          <v-select style="min-width:240px;" class="ml-8" v-model="altCoinID" :options="formattedAltCoins"
            :reduce="coin => coin.ID" label="Name"></v-select>
          <select v-model="payoutSchema" aria-label="" class="form-control ml-8">
            <option value="all">All Payout Schemas</option>
            <option value="">Verified Users Payout Schemas</option>
            <option v-for="(schema, index) in payoutSchemas" :key="index" :value="schema.title">{{ schema.title }}
            </option>
            <option value="0">Unverified Users Payout Schemas</option>
          </select>
          <label class="no-wrap ml-8">
            <input type="checkbox" v-model="isBuyEnabled" /> Only Buy Enabled
          </label>
          <input v-model="keyword" aria-label="Search" class="form-control ml-8" placeholder="Search..."
            style="width: 200px" type="text" @keyup="searchRequestedPayouts()" />
          <div class="flex">
            <button class="btn btn-primary btn-sm btn-flat ml-8" @click="searchPayouts()">Search</button>
            <button class="btn btn-primary btn-sm btn-flat ml-8" @click="openSendFundsModal()">Crypto Withdraw</button>
            <button class="btn btn-primary btn-sm btn-flat ml-8" @click="openCreateTransactionModal()">Crypto Deposit
            </button>
          </div>
          <router-link v-if="merchantID" :to="{ name: 'payouts' }" class="btn btn-default btn-flat btn-sm ml-8"
            tag="a">Back
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="payouts && payouts.length" class="footer">
      <pagination :meta="updatedMeta" @pageChange="updatePage"></pagination>
    </div>
    <div class="table-responsive">
      <table class="table table-condensed table-striped">
        <thead>
          <tr>
            <th></th>
            <th class="pointer" @click="sort('MerchantID')">
              Merchant ID
              <span v-if="sortKey === 'MerchantID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('OwnerNickName')">
              Owner
              <span v-if="sortKey === 'OwnerNickName'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('ShopTitle')">
              Shop
              <span v-if="sortKey === 'ShopTitle'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('FinalVerification')">
              Status
              <span v-if="sortKey === 'FinalVerification'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="text-right pointer" style="width: 210px;min-width: 210px;" @click="sort('PayoutSchema')">
              Payout Schema
              <span v-if="sortKey === 'PayoutSchema'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Buy')">
              Buy Enabled
              <span v-if="sortKey === 'Buy'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('SWAPPreferredCoinID')">
              Auto Swap
              <span v-if="sortKey === 'SWAPPreferredCoinID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Balance')">
              Balance
              <span v-if="sortKey === 'Balance'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th>Payout Actions</th>
            <th class="text-right pr-16 pointer" @click="sort('PayoutTotal')">
              Total Payouts
              <span v-if="sortKey === 'PayoutTotal'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="paginatedPayouts.length > 0">
            <template v-for="(payout, index) in paginatedPayouts">
              <tr :key="index">
                <td>
                  <span class="flex pointer text-success" @click="payout.isExpanded = !payout.isExpanded">
                    <svg v-if="!payout.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <svg v-if="payout.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2"></path>
                    </svg>
                  </span>
                </td>
                <td>
                  <a class="pointer" @click="resetFilters(payout.MerchantID)">
                    {{ payout.MerchantID }}
                  </a>
                </td>
                <td class="no-wrap">
                  <router-link :to="{ name: 'user-detail', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.OwnerNickName }}
                  </router-link>
                </td>
                <td class="no-wrap">
                  <router-link :to="{ name: 'user-detail', params: { id: payout.MerchantID } }" tag="a">
                    {{ payout.ShopTitle }}
                  </router-link>
                </td>
                <td v-html="formatMerchantStatus(payout.FinalVerification)"></td>
                <td class="text-right">
                  <span class="mr-8">{{ payoutSchemaToSlug(payout.PayoutSchema) }}</span>
                  <button v-tooltip="{ content: 'Reject payout', trigger: 'click hover focus' }"
                    class="btn btn-danger btn-flat btn-sm" @click="reject(payout, index)">Reject
                  </button>
                </td>
                <td v-html="formatStatus(payout.Buy, '60px')"></td>
                <td>
                  <label v-if="payout.AutoSwap" class="label label-success mb-0">
                    {{ getCoinName(payout.PreferredCoinID) }}</label>
                  <label v-else class="label label-danger mb-0">disabled</label>
                </td>
                <td class="no-wrap" :class="`${payout.Balance < 0 ? 'bg-danger' : ''}`">
                  <span class="flex align-items-center justify-between">
                    <span>
                      <span class="mr-8">
                        <i class="fa fa-copy pointer"
                          @click="toConverter(toFixed(payout.Balance), payout.AltCoinID)"></i>
                      </span>
                      <router-link :to="{ name: 'coin-health-detail', params: { id: payout.AltCoinID } }" tag="a">
                        <img :src="generateIconUrl(payout)" alt="" class="w-24 mr-8" />
                      </router-link>
                      <router-link tag="a" :to="{ name: 'payouts', query: { id: payout.AltCoinID } }">
                        {{ toFixed(payout.Balance) + ' ' + payout.LongName }}
                      </router-link>
                      <span class="text-yellow ml-4" v-if="isShowSwapWarning(payout)"
                        v-tooltip="{ content: 'Payout currency is different', trigger: 'click hover focus' }">
                        <i class="fa fa-exclamation-circle"></i>
                      </span>
                    </span>
                    <a v-tooltip="{ content: 'Crypto Withdraw', trigger: 'click hover focus' }"
                      class="btn btn-primary btn-sm btn-flat ml-8" @click="openSendFundsModal(payout)">W</a>
                  </span>
                </td>
                <td class="flex align-items-center">
                  <button v-tooltip="{ content: 'Fiat payout', trigger: 'click hover focus' }"
                    :disabled="payout.DisablePayout || !isPayoutPossible(payout)"
                    class="btn btn-primary btn-sm btn-flat mr-8" @click="fiatPayout(payout, index)">F
                  </button>
                  <button v-tooltip="{ content: 'Send Balance to FiatBridge', trigger: 'click hover focus' }"
                    class="btn btn-primary btn-sm btn-flat mr-8" @click="consolidate(payout, index)">B
                  </button>
                  <button v-tooltip="{ content: 'Payout', trigger: 'click hover focus' }"
                    :disabled="payout.DisablePayout" class="btn btn-info btn-sm btn-flat mr-8"
                    @click="payoutBalance(payout, index)">P
                  </button>
                  <button v-tooltip="{ content: 'Prepare daily payout', trigger: 'click hover focus' }"
                    class="btn btn-info btn-sm btn-flat mr-8" @click="prepareDailyPayout(payout, index)">D
                  </button>
                  <button v-tooltip="{ content: 'Thrash it', trigger: 'click hover focus' }"
                    class="btn btn-danger btn-sm btn-flat mr-8" @click="trash(payout, index)">T
                  </button>
                  <button v-tooltip="{ content: 'Swap', trigger: 'click hover focus' }"
                    class="btn btn-primary btn-sm btn-flat mr-8" @click="swap(payout, index)">SW
                  </button>
                  <button v-if="payout.IsExcluded"
                    v-tooltip="{ content: 'Customer has excluded this currency from daily payouts', trigger: 'click hover focus' }"
                    class="btn btn-sm btn-svg mr-8">
                    <svg class="w-24 h-24 text-yellow pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                  </button>
                </td>
                <td class="text-right no-wrap pr-16">{{ toFixed(payout.PayoutTotal) + ' ' + payout.LongName }}</td>
              </tr>
              <template v-if="payout.isExpanded">
                <tr :key="'detail_' + index">
                  <td class="px-15" colspan="9">
                    <div class="row">
                      <div class="col-md-6">
                        <table class="table table-condensed table-striped mb-0">
                          <tbody>
                            <tr>
                              <th colspan="2">Payout Information</th>
                            </tr>
                            <tr>
                              <td>Payout Schema</td>
                              <td>{{ payout.PayoutSchema }}</td>
                            </tr>
                            <template v-if="getPayoutSchemaType(payout.PayoutSchema) === 'bank'">
                              <tr>
                                <td>Bank Account Name</td>
                                <td>{{ payout.PayoutName }}</td>
                              </tr>
                              <tr>
                                <td>IBAN/Account Number</td>
                                <td>{{ payout.PayoutIBAN }}</td>
                              </tr>
                              <tr>
                                <td>BIC/Swift/Routing code</td>
                                <td>{{ payout.PayoutBIC }}</td>
                              </tr>
                            </template>
                            <template v-if="getPayoutSchemaType(payout.PayoutSchema) === 'payeer'">
                              <tr>
                                <td>Paypal/Payeer</td>
                                <td>{{ payout.PayoutTo }}</td>
                              </tr>
                            </template>
                            <template v-if="getPayoutSchemaType(payout.PayoutSchema) === 'address-book-bank'
            && payout.AddressBookBank && payout.AddressBookBank !== 'NA'">
                              <tr>
                                <td>Currency</td>
                                <td>{{ payout.AddressBookBank.currency }}</td>
                              </tr>
                              <tr>
                                <td>Account Holder Name</td>
                                <td>{{ payout.AddressBookBank.accountHolderName }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.accountType">
                                <td>Account Type</td>
                                <td>{{ payout.AddressBookBank.accountType.toLowerCase() }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.IBAN">
                                <td>IBAN</td>
                                <td>{{ payout.AddressBookBank.IBAN }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.accountNumber">
                                <td>Account Number</td>
                                <td>{{ payout.AddressBookBank.accountNumber }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.taxId">
                                <td>Tax Id: CUIL / CUIT</td>
                                <td>{{ payout.AddressBookBank.taxId }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.bsbCode">
                                <td>BSC Code</td>
                                <td>{{ payout.AddressBookBank.bsbCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.bankCode">
                                <td>Bank Code</td>
                                <td>{{ payout.AddressBookBank.bankCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.branchCode">
                                <td>Branch Code</td>
                                <td>{{ payout.AddressBookBank.branchCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.cpf">
                                <td>Recipient tax ID/CPF</td>
                                <td>{{ payout.AddressBookBank.cpf }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.phoneNumber">
                                <td>Account Number</td>
                                <td>{{ payout.AddressBookBank.phoneNumber }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.institutionNumber">
                                <td>Institution Number</td>
                                <td>{{ payout.AddressBookBank.institutionNumber }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.transitCode">
                                <td>Transit Number</td>
                                <td>{{ payout.AddressBookBank.transitCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.rut">
                                <td>Beneficiary's RUT number (Rol Único Tributario)</td>
                                <td>{{ payout.AddressBookBank.rut }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.cardNumber">
                                <td>UnionPay card number</td>
                                <td>{{ payout.AddressBookBank.cardNumber }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.sortCode">
                                <td>Sort Code</td>
                                <td>{{ payout.AddressBookBank.sortCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.ifscCode">
                                <td>IFSC Code</td>
                                <td>{{ payout.AddressBookBank.ifscCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.clabe">
                                <td>Recipient CLABE Number</td>
                                <td>{{ payout.AddressBookBank.clabe }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.swiftCode">
                                <td>Swift Code</td>
                                <td>{{ payout.AddressBookBank.swiftCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.idDocumentType">
                                <td>ID Document Type</td>
                                <td>{{ payout.AddressBookBank.idDocumentType }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.idDocumentNumber">
                                <td>Identification Number</td>
                                <td>{{ payout.AddressBookBank.idDocumentNumber }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.russiaRegion">
                                <td>Region</td>
                                <td>{{ payout.AddressBookBank.russiaRegion }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.abartn">
                                <td>Routing Number</td>
                                <td>{{ payout.AddressBookBank.abartn }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.firstLine">
                                <td>Address</td>
                                <td>{{ payout.AddressBookBank.firstLine }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.city">
                                <td>City</td>
                                <td>{{ payout.AddressBookBank.city }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.state">
                                <td>State</td>
                                <td>{{ payout.AddressBookBank.state }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.postCode">
                                <td>Postal Code</td>
                                <td>{{ payout.AddressBookBank.postCode }}</td>
                              </tr>
                              <tr v-if="payout.AddressBookBank.country">
                                <td>Country</td>
                                <td>{{ payout.AddressBookBank.country }}</td>
                              </tr>
                            </template>
                            <template v-if="getPayoutSchemaType(payout.PayoutSchema) === 'address-book-crypto'
            && payout.AddressBookCrypto && payout.AddressBookCrypto !== 'NA'">
                              <tr>
                                <td>Crypto Currency</td>
                                <td>{{ payout.AddressBookCrypto.altCoinID }} -
                                  {{ selectCoinName(payout.AddressBookCrypto.altCoinID) }}
                                </td>
                              </tr>
                              <tr>
                                <td>Address</td>
                                <td>{{ payout.AddressBookCrypto.address }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-6">
                        <a :href="'https://cointopay.com/CsvExportAdmin.jsp?type=1&AltCoinID=' + payout.AltCoinID + '&MerchantID=' + payout.MerchantID"
                          class="mr-8" target="_blank">
                          <img alt="" class="w-48 h-48" src="/assets/img/csv.svg" />
                        </a>
                        <a :href="'https://cointopay.com/CsvExportAdmin.jsp?type=2&AltCoinID=' + payout.AltCoinID + '&MerchantID=' + payout.MerchantID"
                          target="_blank">
                          <img alt="" class="w-48 h-48" src="/assets/img/csv.svg" />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
          <template v-else>
            <tr>
              <td colspan="11">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="payouts && payouts.length" class="footer">
      <pagination :meta="updatedMeta" @pageChange="updatePage"></pagination>
    </div>
    <!-- Send Funds Modal -->
    <payout-modal></payout-modal>
    <!-- Create Transaction Modal -->
    <create-transaction-modal></create-transaction-modal>
    <!-- Payout Swap Modal -->
    <PayoutSwapModal></PayoutSwapModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  formatMerchantStatus,
  formatStatus,
  getPayoutSchemaType,
  logout,
  payoutSchemas,
  toFixed,
  saveNotification,
  generateIconUrl,
} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import PayoutModal from "@/components/modals/PayoutModal.vue";
import PayoutSwapModal from "@/components/modals/payouts/PayoutSwapModal.vue";
import CreateTransactionModal from "@/components/modals/CreateTransactionModal.vue";
import { SAVE_CONVERTER } from "@/store/keys";
import * as _ from "lodash";

export default {
  name: "RequestedPayouts",
  components: {
    CreateTransactionModal,
    PayoutModal,
    PayoutSwapModal,
    Pagination,
  },
  props: {
    payouts: {
      type: Array,
      default() {
        return [];
      },
    },
    meta: {
      type: Object,
      default() {
        return {
          total: 0,
          page: 1,
          offset: 0,
          limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
        };
      },
    },
    payoutSchemaSearched: {
      type: String,
      default: "",
    },
    altCoinIDSearched: {
      type: String,
      default: "",
    },
    searchKeyword: {
      type: String,
      default: "",
    },
    searchOrder: {
      type: String,
      default: "DESC",
    },
    searchSortKey: {
      type: String,
      default: "MerchantID",
    },
  },
  data() {
    return {
      payoutSchemas: payoutSchemas,
      merchantID: "",
      keyword: "",
      isBuyEnabled: true,
      payoutSchema: "",
      altCoinID: "",
      timer: null,
      searching: "",
      sortKey: "MerchantID",
      order: "desc",
      interval: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "jwtToken",
      "onlineAltCoins",
      "altCoins",
      "converter",
    ]),
    formattedAltCoins() {
      const formattedCoins = this.altCoins.map(a => {
        return {
          ID: a.ID,
          Name: a.Name + " - " + a.LongName + " - " + a.ID + " - " + (a.isOnline ? "Online" : "Offline"),
        };
      });
      formattedCoins.splice(0, 0, {
        ID: "",
        Name: "All Coins",
      });
      return formattedCoins;
    },
    paginatedPayouts() {
      if (this.payouts.length > 0) {
        const offset = this.meta.page > 0 ? this.meta.page - 1 : 0;
        const payouts = this.payouts.filter(p => {
          if (this.isBuyEnabled) {
            return p.Buy === true;
          }
          return p;
        });
        return payouts.slice(offset * this.meta.limit, this.meta.page * this.meta.limit);
      }
      return this.payouts;
    },
    updatedMeta() {
      const payouts = this.payouts.filter(p => {
        if (this.isBuyEnabled) {
          return p.Buy === true;
        }
        return p;
      });
      return {
        ...this.meta,
        total: payouts.length,
      };
    },
    totalBalance() {
      if (this.altCoinIDSearched && this.payouts.length > 0) {
        let balance = 0;
        this.payouts.map(payout => {
          balance += payout.Balance;
        });
        return balance + " " + this.payouts[0].LongName;
      }
      return "";
    },
  },
  methods: {
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.searchPayouts();
    },
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    resetIndex() {
      this.expandedRowIndex = 0;
    },
    toFixed(val) {
      return toFixed(val);
    },
    formatStatus(status, maxWidth) {
      return formatStatus(status, maxWidth);
    },
    formatMerchantStatus(status) {
      return formatMerchantStatus(status);
    },
    updatePage(page) {
      this.$emit("updatePage", page);
    },
    payoutSchemaToSlug(schema) {
      const payoutSchema = this._.find(this.payoutSchemas, { title: schema });
      if (payoutSchema) {
        schema = payoutSchema.slug;
      }
      return schema;
    },
    getPayoutSchemaType(payoutSchema) {
      return getPayoutSchemaType(payoutSchema);
    },
    getCoinName(id) {
      const coin = this._.find(this.altCoins, { ID: id });
      return coin ? coin.Name : id;
    },
    isPayoutPossible(record) {
      const schema = this.getPayoutSchemaType(record.PayoutSchema);
      let isAvailable = false;
      switch (schema) {
        case "address-book-bank":
          isAvailable = record.AddressBookBank !== "NA";
          break;
        case "address-book-crypto":
          isAvailable = record.AddressBookCrypto !== "NA";
          break;
        case "bank":
          isAvailable = record.PayoutIBAN !== "";
          break;
        case "paypal":
        case "payeer":
          isAvailable = record.PayoutTo !== "" && (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(record.PayoutTo));
          break;
      }
      return isAvailable;
    },
    searchRequestedPayouts() {
      this.searching = "Searching...";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.searchPayouts();
      }, 500);
    },
    searchPayouts() {
      this.$emit("search", {
        keyword: this.keyword ? this.keyword.trim() : "",
        payoutSchema: this.payoutSchema,
        altCoinID: this.altCoinID,
        merchantID: this.merchantID,
        order: this.order,
        sortKey: this.sortKey,
      });
    },
    reject(record, index) {
      this.$swal.fire({
        title: "Reject",
        text: `Are you sure, want to reject payout for ${record.ShopTitle}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd4b39",
        confirmButtonText: "Reject",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "AccountInfoReminder",
            MerchantID: this.currentUser.ID,
            APIKey: "_",
            targetMerchantID: record.MerchantID,
            PayoutSettingsChangeOnly: "",
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            const msgType = (response.data.status_code === 200) ? "success" : "error";
            if (msgType === "success") {
              this.updateLogs(record, index, "Payout Schema Rejected");
              saveNotification("Payout Schema Rejected");
            }
          }).catch(error => {
            console.log(error); // TODO: show toast message
            this.$swal.close();
          });
        }
      });
    },
    updateLogs(record, index, status) {
      this.loader = "update_logs_" + index;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const encodeForm = data => {
        return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&");
      };
      const action = this.tz().format("DD/MM/YYYY HH:mm:ss") + " " + this.currentUser.Username + ": status: " + status + "\n";
      const payload = {
        Call: "KYCLOG",
        MerchantID: record.MerchantID,
        APIKey: record.APIKey,
        kyclog: action + record.Logs,
        output: "json",
      };
      this.$http.post(`${apiBaseUrl}/v2REAPI`, encodeForm(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        const msgType = (response.data.status_code === 200) ? "success" : "error";
        if (msgType === "success") {
          this.payouts.splice(index, 1);
          this.$toast.fire("", response.data.message, msgType);
          saveNotification(response.data.message);
        }
        this.loader = "";
      }).catch(error => {
        console.log(error);
        this.loader = "";
      });
    },
    // Actions
    getPayoutAddress(record) {
      const schema = this.getPayoutSchemaType(record.PayoutSchema);
      let address = null;
      switch (schema) {
        case "address-book-bank":
          address = record.AddressBookBank;
          break;
        case "address-book-crypto":
          address = record.AddressBookCrypto;
          break;
        case "bank":
          address = {
            accountHolderName: record.PayoutName,
            accountNumber: record.PayoutIBAN,
            bankCode: record.PayoutBIC,
          };
          break;
        case "payeer":
          address = {
            email: record.PayoutTo,
          };
          break;
      }
      return address;
    },
    fiatPayout(record, index) {
      this.$swal.fire({
        text: "Fiat payout",
        input: "text",
        inputValue: toFixed(record.Balance),
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
        inputValidator: (value) => {
          if (!value || value <= 0) {
            return "Balance should be greater than 0";
          } else if (value > record.Balance) {
            return "Balance can not be greater than " + toFixed(record.Balance);
          }
        },
      }).then(({ value }) => {
        if (value && value > 0) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          let payload = {
            Call: "FiatPayout",
            MerchantID: record.MerchantID,
            APIKey: record.APIKey,
            AltCoinID: record.AltCoinID,
            Amount: toFixed(value),
            output: "json",
          };
          if (record && this.getPayoutSchemaType(record.PayoutSchema) !== "payeer") {
            payload = Object.assign(payload, this.getPayoutAddress(record));
          }
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            if (response.data.toLowerCase() !== "ok") {
              this.$toast.fire("", response.data, "error");
            } else {
              if (value.toString() === toFixed(record.Balance).toString()) {
                this.payouts.splice(index, 1);
              } else {
                record.Balance = Number.parseFloat(record.Balance) - Number.parseFloat(value);
                record.PayoutTotal = Number.parseFloat(record.PayoutTotal) + Number.parseFloat(value);
                this.payouts.splice(index, 1, record);
              }
              this.$toast.fire("", "Fiat payout successful", "success");
              saveNotification("Fiat payout successful");
            }
          }).catch(error => {
            this.$swal.close();
            console.log(error);
          });
        }
      });
    },
    consolidate(record, index) {
      this.$swal.fire({
        title: "Send Balance to FiatBridge",
        text: "Are you sure",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          this.$http.get(`${apiBaseUrl}/GetInfo`, {
            params: {
              Name: "B",
              Secure: "AA",
              PoolCheck: 1,
              Consolidate: 2,
              AltCoinID: record.AltCoinID,
              Amount: toFixed(record.Balance),
            },
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", "Balance sent to FiatBridge successfully", "success");
                saveNotification("Balance sent to FiatBridge successfully");
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            this.$swal.close();
            console.log(error);
          });
        }
      });
    },
    trash(record, index) {
      this.$swal.fire({
        title: "Trash balance",
        text: "Do you want to thrash the full (" + toFixed(record.Balance) + ") amount of " + record.LongName + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd4b39",
        confirmButtonText: "Trash it",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.processThrashIt(record);
        } else {
          const balance = Number(toFixed(record.Balance)); // .toFixed(8)
          this.$swal.fire({
            text: "Amount",
            html: `
              <div class="form-group text-left">
                <label for="amount">Amount</label>
                <input type="text" class="form-control" value="${balance}" id="amt" required/>
              </div>
              <div class="form-group text-left">
                <label for="message">Message</label>
                <textarea class="form-control" placeholder="Type your message here" id="message" rows="3"></textarea>
              </div>
            `,
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            focusCancel: true,
            preConfirm: () => {
              const amount = document.getElementById("amt").value;
              const message = document.getElementById("message").value;
              const pattern = new RegExp("^([A-Za-z0-9-:._!@#$^&*+?| /]+)$", "g");
              if (amount === "" || amount <= 0 || amount > balance) {
                this.$swal.showValidationMessage("Please provide lower than amount " + balance);
              } else if (message !== "" && pattern.test(message) === false) {
                this.$swal.showValidationMessage("Message can only contain alphabets, numbers, space, underscore, hyphen and dot.");
              } else {
                return [
                  amount, message,
                ];
              }
            },
          }).then(({ value }) => {
            if (value && value.length === 2) {
              this.processThrashIt(record, value[0], value[1]);
            }
          });
        }
      });
    },
    processThrashIt(record, balance = null, message = "") {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "Thrashit",
        MerchantID: record.MerchantID,
        APIKey: record.APIKey,
        AltCoinID: record.AltCoinID,
        Amount: balance ? toFixed(balance) : toFixed(record.Balance),
        Message: message,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
        withCredentials: true,
      }).then(response => {
        this.$swal.close();
        if (response.status === 200) {
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
          }
          this.$toast.fire("", "Balance trashed successfully", "success");
          saveNotification("Balance trashed successfully");
          let counter = 1;
          this.interval = setInterval(() => {
            counter++;
            if (counter >= 5) {
              clearInterval(this.interval);
              this.interval = null;
            }
            this.$emit("reloadRequestPayouts");
          }, 3000);
        }
      }).catch(error => {
        this.$swal.close();
        console.log(error);
      });
    },
    payoutBalance(record, index) {
      this.$swal.fire({
        text: "Are you sure, want to payout",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "Payout",
            MerchantID: record.MerchantID,
            APIKey: record.APIKey,
            AltCoinID: record.AltCoinID,
            Amount: toFixed(record.Balance),
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            if (typeof response.data === "string" && response.data.toLowerCase() === "ok") {
              this.$toast.fire("", "Payout successful", "success");
              saveNotification("Payout successful");
              record.DisablePayout = true;
              this.payouts.splice(index, 1, record);
            }
          }).catch(error => {
            this.$swal.close();
            console.log(error);
          });
        }
      });
    },
    swap(record, index) {
      const coins = this._.orderBy(this.onlineAltCoins, ["Name"], ["asc"]);
      const selectedCoin = record.PreferredCoinID || 1;
      this.$bus.$emit("payoutSwapModal", {
        coins,
        selectedCoin,
        record,
      });
    },
    prepareDailyPayout(record, index) {
      this.$swal.fire({
        text: "Prepare daily payout",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            MerchantID: record.MerchantID,
            Name: "B",
            AltCoinID: 1,
            Secure: "AA",
            PREPAREDAILYPAYMENT: 1,
          };
          this.$http.get(`${apiBaseUrl}/GetInfo`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            if (response.data.status_code === 200) {
              const message = response.data.message.toLowerCase();
              const match = "result:not eligable for daily automated payout: transactiontypes not supported";
              if (message !== match) {
                this.$toast.fire("", "Daily payouts prepared successfully", "success");
                saveNotification("Daily payouts prepared successfully");
              } else if (message === match) {
                this.$toast.fire("", "Not eligible for daily automated payout", "error");
                saveNotification("Not eligible for daily automated payout");
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            this.$swal.close();
            console.log(error);
          });
        }
      });
    },
    openSendFundsModal(record = null) {
      this.$bus.$emit("send-funds-modal", {
        title: "Crypto withdraw",
        data: record !== null ? record : this.payouts,
      });
    },
    openCreateTransactionModal() {
      this.$bus.$emit("create-transaction-modal", {
        title: "Crypto deposit",
        data: this.payouts,
      });
    },
    resetFilters(merchantID) {
      this.merchantID = merchantID;
      if (Object.keys(this.$route.params).length > 0) {
        if (this.$route.params.id === this.merchantID) {
        }
      } else {
        this.$router.replace({
          ...this.$router.currentRoute,
          params: {
            id: merchantID,
          },
        });
      }

      this.payoutSchema = "all";
      this.altCoinID = "";
      this.searchPayouts();
    },
    selectCoinName(id) {
      const coin = _.find(this.onlineAltCoins, { ID: Number(id) });
      if (coin) {
        return `${coin.Name} (${coin.LongName})`;
      }
      return "";
    },
    isShowSwapWarning(payout) {
      if (payout.AddressBookCrypto !== "NA") {
        return payout.AltCoinID !== payout.AddressBookCrypto.altCoinID;
      }
      return false;
    },
    generateIconUrl(record) {
      return generateIconUrl(record.CoinName);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.merchantID = this.$route.params.id;
    } else {
      this.merchantID = "";
    }
    this.payoutSchema = this.payoutSchemaSearched;
    this.altCoinID = this.altCoinIDSearched !== "" ? Number(this.altCoinIDSearched) : "";
    this.keyword = this.searchKeyword;
    this.order = this.searchOrder;
    this.sortKey = this.searchSortKey;
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style scoped lang="scss"></style>
